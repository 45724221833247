.Skill {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.Skill svg {
  color: var(--secondary);
  fill: var(--secondary);
  stroke: var(--secondary);
  font-size: 2.7rem;
  margin-bottom: 1vh;
}

.Skill svg path {
  stroke: var(--secondary);
  font-size: 2.7rem;
}

.Skill h2 {
  font-family: bebas-neue-pro;
  color: var(--secondary);
  font-size: 6vh;
  font-weight: var(--titleFont);
  text-align: center;
  margin-bottom: 1vh;
}

.Skill p {
  text-align: justify;
  font-size: 2.2vh;
  font-weight: 300;
}

.Skill.active {
  animation: 0.4s ease-out forwards store;
}

.Skill:nth-child(2).active {
  animation-delay: 0.4s;
}

.Skill:nth-child(3).active {
  animation-delay: 0.6s;
}

.Skill:nth-child(5).active {
  animation-delay: 0.4s;
}

.Skill:nth-child(6).active {
  animation-delay: 0.6s;
}

.Skill:nth-child(8).active {
  animation-delay: 0.4s;
}

.Skill:nth-child(9).active {
  animation-delay: 0.6s;
}

@keyframes store {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

@media screen and (min-width: 1440px) {
  .Skill h2 {
    font-size: 2.5vw;
  }

  .Skill h2 {
    font-size: 3vw;
  }
}
