@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap");
@import url("https://use.typekit.net/eda8tlg.css");
/*
1. Variables
2. RESET
3. Blocks
4. Inline
5. ID
6. Classes
*/

/* Variables */

:root {
  --white: #fdf7fa;
  --body: black;
  --primary: #e9d1b1;
  --secondary: #ecc5d8;
  --third: #393646;
  --bodyLight: #837f9b;
  --bodyDark: #463f55;
  --pagination: #4c4860;
  --currentPage: #46546e;
  --author: #2d2837;
  --input: #a4a1b6;
  --success: #e9d1b1;
  --error: #ecc5d8;
  --info: #837f9b;
  --button: #ae7a93;
  --font: "helvetica", arial, sans-serif;
  --citeFont: "Homemade Apple", cursive;
  --bebas: "bebas-neue-pro";
  --titleFont: 300;
  --sliderFont: 400;
  --homeFont: 600;
}

/* Bebas Neue Pro Book
font-weight: 300;

Bebas Neue Pro Regular
font-weight: 400;

Bebas Neue Pro Bold
font-weight: 600; */

/* 2 RESET */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

*::selection {
  color: #ae7a93;
  background-color: var(--secondary);
}

html,
body {
  height: 100%;
  cursor: url("./assets/images/cursor.png"), auto;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font);
  background-color: var(--body);
  width: 100%;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

/* 3 Block */

section {
  min-height: 100vh;
  width: 100%;
}

iframe {
  max-width: 100%;
}

input[type="text"],
input[type="number"],
input[type="url"],
input[type="email"],
input[type="password"],
textarea {
  padding-left: 1vh;
  background: var(--input);
  color: var(--bodyDark);
  margin-bottom: 2vh;
  font-size: 3vh;
  border: none;
  outline: none;
  width: 100%;
  transition: all 1s ease-in-out;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  background: var(--white);
  transition: all 1s ease-in-out;
}

::placeholder {
  color: var(--bodyDark);
}

/* 4 inline */

table {
  color: var(--primary);
  font-size: 2vh;
  border-collapse: collapse;
  border-radius: 5px;
  font-weight: normal;
  border: none;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  margin-bottom: 3vh;
}

td,
th,
thead,
table {
  padding: 8px;
}

th {
  background-color: var(--primary);
  color: var(--white);
}

td {
  text-align: center;
}
td:not(:last-child) {
  border-right: 1px solid rgba(66, 219, 140, 0.2);
}

tr:nth-child(even) td {
  background-color: rgba(66, 219, 140, 0.2);
  color: var(--body);
}

a {
  color: var(--primary);
}

p {
  font-size: 1.2rem;
  font-family: var(--font);
  color: var(--white);
}

cite {
  color: var(--white);
}

cite::before {
  content: "- ";
}

cite::after {
  content: " -";
}

q {
  color: var(--white);
}

h1 {
  color: var(--primary);
  font-size: 5vh;
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  text-transform: uppercase;
  font-weight: 100;
  line-height: 1;
}

h2 {
  color: var(--primary);
  font-family: bebas-neue-pro;
  font-size: 4rem;
  font-weight: var(--titleFont);
  text-transform: uppercase;
  line-height: 1;
}

h3 {
  color: var(--secondary);
  font-family: var(--titleFont);
  font-size: 2.2vw;
  text-transform: uppercase;
}

li {
  color: var(--white);
}

button {
  border: 0;
  border-radius: 0.25rem;
  background: var(--secondary);
  color: var(--button);
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  font-size: 2rem;
  font-weight: 100;
  line-height: 1.2;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5vw 2vw;
  margin: 0.25rem;
  cursor: pointer;
  transition: all 1s;
}

button:hover {
  background: var(--button);
  color: var(--white);
  transition: all 1s;
}

hr {
  color: var(--primary);
  border-color: var(--primary);
  background-color: var(--primary);
}

/* 5 ID */
/* 6 Classes */

.delete {
  background-color: var(--alert);
  color: var(--white);
  font-size: 4vh;
  padding: 1vh;
  border-radius: 5px;
  cursor: pointer;
}

.delete:hover {
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
}

.error {
  background-color: var(--error);
  color: var(--button) !important;
}

.flash {
  position: fixed;
  top: 40%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  width: 80vw;
  min-height: 5vh;
  height: auto;
  border-radius: 5px;
  color: var(--white);
  font-family: var(--font);
  font-size: 2rem;
  text-align: center;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vh;
}

.has-text-align-center,
center {
  text-align: center;
  color: var(--white);
}

.info {
  background-color: var(--info);
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 10vh;
  color: var(--white);
  font-family: var(--titleFont);
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.mobile {
  display: flex;
}

.not-mobile {
  display: none;
}

.push {
  height: 30vh;
}

.success {
  background-color: var(--success);
  color: #796c5c !important;
}

.wp-block-embed__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wp-block-embed__wrapper iframe {
  width: 100% !important;
}

/* Classes Bootstrapé pour wordpress et pour simplifier */

.text-book {
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  text-transform: uppercase;
}

.text-regular {
  font-family: bebas-neue-pro;
  font-weight: var(--sliderFont);
}

.text-bold {
  font-family: bebas-neue-pro;
  font-weight: var(--homeFont);
}

.text-hand {
  font-family: var(--citeFont);
}

.primary-color {
  color: var(--primary);
}

.primary-bg {
  background-color: var(--primary);
}

.secondary-color {
  color: var(--secondary);
}

.secondary-bg {
  background-color: var(--secondary);
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 7rem;
  }

  .mobile {
    display: none;
  }

  .not-mobile {
    display: flex;
  }
}
