.About {
  background: var(--body);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
}

.About .shannon {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About .shannon .shannon-image {
  max-width: 100%;
  width: 100%;
  left: 0;
  object-fit: contain;
}

.About .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About .text {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vw;
  text-align: justify;
}

.About p {
  margin-bottom: 3vh;
}

@media screen and (min-width: 1200px) {
  .About {
    flex-direction: row;
    padding-bottom: 0;
  }

  .About .shannon {
    width: 40vw;
    height: 100vh;
  }

  .About .shannon .shannon-image {
    margin-left: -15vw;
    height: 150vh;
  }

  .About .text {
    width: 60vw;
    height: 100vh;
    padding: 10vh;
    align-items: flex-start;
  }

  .About p {
    margin-bottom: 3vh;
  }
}
