.Footer {
  background: url("../../assets/images/footer.jpg");
  background-size: cover;
  height: 50vh;
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
  position: absolute;
  right: 0;
  left: 0;
  margin-top: -17vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer .content {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Footer .content hr {
  width: 15%;
  border: 1px solid var(--secondary);
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.Footer .content .quote {
  font-family: var(--citeFont);
  font-size: 5vw;
  line-height: 1;
  color: var(--white);
}

.Footer .content .quote span {
  color: var(--secondary);
  font-size: 7vh;
  line-height: 0.4;
  height: 1vh;
}

.Footer .content cite {
  font-size: 5vw;
  color: var(--secondary);
  text-transform: uppercase;
  margin-bottom: 2vh;
}

.Footer .bottom .infos,
.Footer .bottom .legal {
  color: var(--white);
}

@media screen and (min-width: 1200px) {
  .Footer {
    height: 80vh;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    background-attachment: fixed;
  }

  .Footer .content .quote {
    font-size: 3vw;
  }

  .Footer .content .quote span {
    font-size: 10vh;
  }

  .Footer .content cite {
    font-size: 1.5vw;
  }
}
