.SearchResult {
  padding: 10vh;
  background-color: var(--bodyLight);
  padding-bottom: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SearchResult .results-grid {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3rem;
  padding-top: 3vh;
}

.SearchResult .results-grid div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--secondary);
  background-color: var(--third);
}
