.ArticleSingle {
  background-color: var(--bodyDark);
  width: 100%;
  padding-top: 10vh;
}

.ArticleSingle .content {
  max-width: 1440px;
  padding: 5vw;
  padding-top: 3vh;
  margin: auto;
  text-align: justify;
}

.ArticleSingle .Categories {
  margin-top: 0;
  margin-bottom: 2vh;
  z-index: 5;
  position: relative;
}

.ArticleSingle .fimg {
  height: 50vh;
  background-size: cover;
  background-position: bottom center;
}

.ArticleSingle .prev,
.ArticleSingle .next {
  position: fixed;
  height: 10vh;
  top: 50%;
  border-radius: 0;
  background-color: var(--secondary);
  color: #ae7a93;
  font-size: 5vh;
  opacity: 0;
  transition: all 1s;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.ArticleSingle .prev.active,
.ArticleSingle .next.active {
  opacity: 0.6;
}

.ArticleSingle .prev {
  left: 0;
}

.ArticleSingle .next {
  right: 0;
}

.ArticleSingle .date {
  color: var(--input);
  position: relative;
  padding-top: 1vh;
  display: block;
  font-family: bebas-neue-pro;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.ArticleSingle .text p:not(:first-child) {
  margin-top: 2vh;
}

.ArticleSingle .text figure {
  margin-top: 2vh;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ArticleSingle .text figure img {
  width: 100% !important;
  height: auto;
}

.ArticleSingle .up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
}

.ArticleSingle .author {
  background-color: var(--author);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color: var(--white);
}

.ArticleSingle .author .thumbnail {
  object-fit: contain;
  width: 80%;
  height: auto;
}

.ArticleSingle .author .text div {
  margin-top: 3vh;
  height: 8vh;
  font-size: 2.2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ArticleSingle .author .text h3 {
  font-size: 7vh;
}

.ArticleSingle .author .text p {
  font-size: 2.5vh;
}

.ArticleSingle .counter {
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
  background-color: var(--bodyLight);
  font-family: var(--titleFont);
  position: relative;
}

.ArticleSingle .counter::after {
  content: "";
  position: absolute;
  background-color: var(--bodyLight);
  width: 2rem;
  height: 1rem;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 90%);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

@media screen and (min-width: 1200px) {
  .ArticleSingle .author {
    flex-direction: row;
    align-items: flex-start;
  }

  .ArticleSingle .author .text {
    margin-left: 2rem;
  }

  .ArticleSingle .author .text div {
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  .ArticleSingle .author .text h3 {
    font-size: 4vh;
  }

  .ArticleSingle .author .text p {
    font-size: 1.5vh;
    font-weight: 100;
  }

  .ArticleSingle .counter {
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;
    text-align: center;
    background-color: var(--bodyLight);
    font-family: var(--titleFont);
    position: relative;
  }

  .ArticleSingle .author .thumbnail {
    width: 10vw;
    height: 10vw;
  }

  .ArticleSingle .fimg {
    background-attachment: fixed;
  }

  .ArticleSingle .prev:hover,
  .ArticleSingle .next:hover {
    opacity: 1;
    transition: all 1s;
  }

  .ArticleSingle .prev:hover {
    transform: translateX(0);
  }

  .ArticleSingle .prev {
    left: 0;
    transform: translateX(-50%);
    visibility: visible;
  }

  .ArticleSingle .next {
    visibility: visible;
    right: 0;
    transform: translateX(50%);
  }

  .ArticleSingle .next:hover {
    right: 0;
    transform: translateX(0);
  }

  .ArticleSingle .Categories .parent:hover .sub:before {
    background-color: var(--bodyLight);
  }
}
