.Software {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3vh;
}

.Software .software-name {
  color: var(--white);
  font-size: 2rem;
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  text-transform: uppercase;
}

.Software .level-container {
  background-color: var(--white);
  height: 0.7rem;
  border-radius: 25px;
  width: 100%;
}

.Software .level-container .level {
  height: 100%;
}

.Software .level-container .animation.active {
  background-color: var(--third);
  height: 100%;
  border-radius: 25px;
  animation: 1s ease-in-out grow;
}

@media screen and (min-width: 1200px) {
  .Software {
    width: 90%;
  }
}

@keyframes grow {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
