.Slider {
  height: 50vh;
  width: 100%;
}

.Slider .item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Slider .item-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 1s;
  overflow: hidden;
}

.Slider .item-bg:hover {
  filter: saturate(70%);
  transform: scale(1.2);
  transition: all 1s;
}

.Slider .item-bg:hover h2 {
  transform: scale(0.7);
  transition: all 1s;
}

.Slider .item a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Slider h2 {
  text-align: center;
  position: relative;
  z-index: 6;
  background-color: rgba(57, 54, 70, 0.9);
  padding: 1vw 2vw;
  transition: all 1s;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #ae7a93 !important;
  font-size: 5vh !important;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: var(--secondary);
  width: 6vh !important;
  height: 15vh !important;
  transform: translateY(-39%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: auto !important;
  right: auto !important;
}

.swiper-button-prev {
  left: 0 !important;
  right: auto !important;
}

.swiper-button-next {
  left: auto !important;
  right: 0 !important;
}

.swiper-pagination-bullet {
  background: var(--white) !important;
  width: 1.5vh !important;
  height: 1.5vh !important;
}
