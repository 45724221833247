.Skills {
  background-image: url("../../assets/images/skill-bg-repeat.jpg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  width: 100%;
}

.Skills .skills-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .Skills {
    padding-top: 0;
    min-height: 100vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .Skills .skills-container {
    height: 90%;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
