.Home {
  background-image: url("../../assets/images/home.jpg");
  background-size: cover;
  padding: 20vh 5vw 0 5vw;
  position: relative;
  overflow: hidden;
}

.Home .transition {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20vh;
  width: 100%;
  background-color: var(--body);
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--secondary);
  font-weight: 100;
  font-size: 20vw;
  line-height: 1;
}

.Home .citations {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.Home .citations h2 {
  font-family: bebas-neue-pro;
  font-weight: var(--sliderFont);
  text-transform: uppercase;
}

.Home .citations h2 span {
  line-height: 0.7;
  margin-bottom: 2vh;
}

.Home .citations .quote {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1;
  margin-top: 4vh;
  color: var(--white);
}

.Home .citations cite {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
}

.Home .welcome {
  color: var(--white);
  font-family: bebas-neue-pro;
  font-weight: var(--homeFont);
  text-transform: uppercase;
  font-size: 7vw;
  line-height: 0.7;
  margin-bottom: 2vh;
}

.Home .Typewriter {
  font-family: var(--titleFont);
  line-height: 0.5;
  font-size: 0.6em;
  width: 100%;
  color: var(--primary);
}

.Home .shannon {
  height: 60vh;
  width: 100%;
  background-image: url("../../assets/images/shannon.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 10vh;
}

.Home .portfolio-link {
  bottom: 0;
  left: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  z-index: 3;
  font-size: 2.7vh;
  transform: translate(-50%, -16vh) rotate(-6deg);
  transform-origin: center;
  background-color: var(--body);
  padding: 0.25rem 1rem 0 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .Home {
    padding: auto;
  }

  .Home .transition {
    height: 30vh;
  }

  .Home h2 {
    font-size: 5.5vw;
  }

  .Home .citations {
    position: absolute;
    width: 45vw;
    top: 20vh;
    left: 5vw;
  }

  .Home .citations .quote,
  .Home .citations cite {
    font-size: 1.5rem;
  }

  .Home .shannon {
    height: 100vh;
    width: 60vw;
    background-position: bottom 25vh right -7vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(5vh);
  }

  .Home .portfolio-link {
    left: 50%;
    font-size: 2rem;
    transform: translate(-50%, -24vh) rotate(-3deg);
  }
}
