.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: auto;
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  position: absolute;
  bottom: 7vh;
  left: 50%;
  transform: translateX(-50%);
}

.Pagination > div:hover:not(.current),
.Pagination > svg:hover {
  background-color: var(--third);
  transition: all 1s;
}

.Pagination div:not(:last-child),
.Pagination svg:not(:last-child) {
  margin-right: 0.5vw;
}

.Pagination .next,
.Pagination .prev,
.current,
.total {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  color: var(--white);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.current {
  background-color: var(--currentPage);
  transition: all 1s;
}

.Pagination .next,
.Pagination .prev,
.total {
  cursor: pointer;
  background-color: var(--pagination);
  transition: all 1s;
}

.Pagination .next polyline,
.Pagination .prev polyline {
  stroke: var(--white);
}

.Pagination .next,
.Pagination .prev {
  padding: 0.7rem;
}

@media screen and (min-width: 1200px) {
  .Pagination {
    height: 5vh;
  }
}
