.NotFound {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.NotFound::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../../assets/images/home.jpg");
  opacity: 0.1;
}
