.Categories {
  height: auto;
  margin-top: 5vh;
}

.Categories > ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  height: auto;
}

.Categories .parent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: bebas-neue-pro;
  font-weight: var(--titleFont);
  font-size: 10vw;
  text-transform: uppercase;
  color: var(--white);
  position: relative;
  cursor: pointer;
  margin-bottom: 1vh;
}

.Categories .parent span {
  background-color: #4c4860;
  color: #ae9fd9;
  width: 100%;
  text-align: center;
  position: relative;
}

.Categories .parent svg {
  position: absolute;
  z-index: 4;
  right: 5vw;
  top: 50%;
  transform: translateY(-50%);
}

.Categories .parent .sub {
  display: flex;
  transform: scaleY(0);
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 4;
  height: 0;
  padding-top: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out, opacity 0.2s;
}

.Categories .parent .sub.active {
  transform: scaleY(1);
  padding-top: 1vh;
  height: auto;
  opacity: 1;
  transition: all 0.4s ease-in-out, opacity 0.2s;
}

.Categories .parent .sub li {
  width: 100%;
  height: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25vh;
  text-transform: uppercase;
  font-size: 5vw;
  font-family: var(--font);
  cursor: pointer;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .Categories {
    height: 5vh;
  }

  .Categories > ul {
    height: 100%;
    flex-wrap: nowrap;
  }

  .Categories .parent {
    width: auto;
    height: 100%;
    margin-bottom: 0;
    font-size: 2vw;
    padding-right: 5vw;
  }

  .Categories .parent .sub {
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5vh;
    z-index: 4;
    height: auto;
    transform: scaleY(1);
  }

  .Categories .parent span {
    background-color: transparent;
    color: var(--white);
    width: auto;
    text-align: center;
    transition: all 0.4s;
  }

  .Categories .parent:hover span {
    color: var(--secondary);
    transition: all 0.4s;
  }

  .Categories .parent:hover .sub {
    display: flex;
    position: absolute;
    top: 5vh;
    margin-top: auto;
    opacity: 1;
  }

  .Categories .parent:hover .sub:before {
    content: "";
    width: 1.5rem;
    height: 1rem;
    background-color: #4c4860;
    margin-bottom: 1rem;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    animation: 0.6s ease-in-out forwards enterLeft;
  }

  .Categories .parent:hover .sub li {
    animation: 0.6s ease-in-out forwards enterLeft;
    transform: translateX(-100%);
    opacity: 0;
    font-size: 1.2vw;
  }

  .Categories .parent:hover .sub li:nth-child(2) {
    animation-delay: 0.1s;
  }

  .Categories .parent:hover .sub li:nth-child(3) {
    animation-delay: 0.2s;
  }

  .Categories .parent:hover .sub li:nth-child(4) {
    animation-delay: 0.3s;
  }

  .Categories .parent:hover .sub li:nth-child(5) {
    animation-delay: 0.4s;
  }

  .Categories .parent:hover .sub li:nth-child(6) {
    animation-delay: 0.5s;
  }

  .Categories .parent .sub.active {
    transform: scaleY(1);
    padding-top: 0;
    height: auto;
    opacity: 1;
    transition: all 0.4s ease-in-out, opacity 0.2s;
  }

  .Categories .parent:hover .sub li {
    background-color: #4c4860;
    color: #ae9fd9;
  }

  .Categories .parent:hover .sub li:hover {
    background-color: var(--currentPage);
    color: var(--white);
  }
}

@keyframes enterLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
