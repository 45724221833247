.Title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 5vw;
}

.Title h2 {
    font-size: 2.4rem;
    line-height: 105%;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 1vh;
}

.Title hr {
    width: 100%;
    margin-bottom: 2vh;
}

@media screen and (min-width: 1200px) {
    .Title {
        margin-bottom: 2vh;
    }

    .Title h2 {
        font-size: 4rem;
    }
}
