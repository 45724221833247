.Articles {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5vw 5vw 15vh 5vw;
  position: relative;
}

.Articles a {
  width: 90vw;
  height: 90vw;
  margin-bottom: 2vh;
}

@media screen and (min-width: 1200px) {
  .Articles {
    height: 100vh;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
    padding: 5vh 10vh 15vh 10vh;
  }

  .Articles a {
    width: 100%;
    height: 100%;
    margin-bottom: 2vh;
  }
}
